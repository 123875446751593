import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Button, Navbar, Timeline, TitleDivider } from "../components";
import { fetchData } from "../fetch/get";
import { BannerContent } from "../fetch/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerWrapper: {
      position: "relative",
    },
    backgroundVideo: {
      objectFit: "cover",
      width: "100%",

      position: "absolute",

      left: 0,
      zIndex: 0,
      [theme.breakpoints.up("sm")]: {
        height: "94vh",
      },
    },

    banner: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "700px",

      color: theme.palette.common.white,
      backgroundBlendMode: "multiply",
      [theme.breakpoints.down("sm")]: {
        minHeight: "500px",
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),

        paddingBottom: theme.spacing(2),
      },
    },
    fullHeight: {
      height: "100%",
    },

    whiteLogo: {
      zIndex: 5,
      paddingLeft: theme.spacing(1),
    },
    buttonScroll: {
      color: theme.palette.primary.main,
      textAlign: "center",
      zIndex: 3,
      transition: [
        theme.transitions.create("color"),
        theme.transitions.create("transform"),
      ].join(","),
      "&:hover, &:focus": {
        color: theme.palette.common.white,
        transform: `translateY(${theme.spacing(1)}px)`,
        cursor: "pointer",
      },
    },
    subHeading: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    arrowIndicatorRegular: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    detailsWrapper: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    contactNumber: {
      marginLeft: theme.spacing(1),
      color: theme.palette.common.white,
      textDecoration: "none",
      display: "block",
      transition: "0.3s ease-in-out",
    },
    registerButtonWrapper: {
      textDecoration: "none",
    },
    navItems: {
      display: "flex",
      justifyContent: "space-between",
      zIndex: 5,
      width: "100%",
      alignItems: "center",
    },
    inquireBtn: {
      textDecoration: "none",
    },
    startDemo: {
      boxShadow: "0 0 17px red",
      animation: "$pulsate 1s ease-out infinite",
    },

    "@keyframes pulsate": {
      "0%": { boxShadow: "0 0 0 red" },
      "50%": { boxShadow: "0 0 15px red" },
      "100%": { boxShadow: "0 0 0 red" },
    },

    modalBody: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backdropFilter: "blur(2px)",
      backgroundColor: "rgba(35, 35, 35, 0.8)",
    },
    wrapper: {
      position: "relative",
      width: "80%",
      height: "80%",
    },
    exitWrapper: {
      position: "absolute",
      top: "-20px",
      right: "0px",
      zIndex: 999999,
      cursor: "pointer",
    },
  })
);

/* eslint-disable-next-line */
interface BannerProps {
  click: () => void;
  clickLiveSupport: () => void;
  clickEcommerce: () => void;
  liveSupportButtonRef: React.RefObject<HTMLDivElement>;
  bannerRef?: React.RefObject<HTMLDivElement>;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

export const Banner = (props: BannerProps) => {
  const matchMobileDevices = useMediaQuery("(max-width:600px)");
  const bannerHeight = window?.innerHeight || parseInt("100vh");
  const scrollOffset = bannerHeight - 64;

  const handleOpen = () => {
    props?.setOpenModal(true);
  };

  const handleClose = () => {
    props.setOpenModal(false);
  };

  const classes = useStyles(props);
  const backgroundHeight = matchMobileDevices ? bannerHeight : "100vh";
  const [content, setContent] = useState<BannerContent>();
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  const size = useWindowSize();

  const body = (
    <div className={classes.modalBody}>
      <div className={classes.wrapper}>
        <div className={classes.exitWrapper} onClick={handleClose}>
          <img src="/img/close.svg" alt="" width="20px" />
        </div>
        <video width="100%" height="100%" autoPlay controls>
          <source
            src="https://inspection-upload-provider.s3.eu-central-1.amazonaws.com/demo_inspection.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );

  useEffect(() => {
    const runFetch = async () => {
      setContent(await fetchData("banners"));
    };
    runFetch();
  }, []);

  const scrollDown = () =>
    window.scrollBy({
      top: scrollOffset,
      left: 0,
      behavior: "smooth",
    });

  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window?.innerWidth,
          height: window?.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }
  return (
    <div className={classes.bannerWrapper}>
      <Container
        maxWidth={false}
        className={classes.banner}
        style={{
          height: backgroundHeight,
        }}
        disableGutters
      >
        <div
          style={{
            width: "100%",
            height: bannerHeight || "100vh",
            zIndex: 2,
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundSize: "cover",

            background:
              "radial-gradient(at right center, rgba(35, 35, 35, 0.1) -5%, rgb(35, 35, 35) 100%)",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: bannerHeight || "100vh",
            zIndex: 0,
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundSize: "cover",

            backgroundImage: `url(${content?.[0]?.background?.[0]?.url})`,
          }}
        ></div>

        <Container className={classes.fullHeight}>
          <Grid
            container
            justify="space-between"
            direction="row"
            className={classes.fullHeight}
          >
            {content?.[0]?.logo?.[0]?.url !== undefined && (
              <Navbar logo={content?.[0]?.logo?.[0]?.url} />
            )}

            <Grid item xs={12} />
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              style={{ zIndex: 2 }}
            >
              <Grid item>
                <Grid item sm={6}>
                  <Fade triggerOnce delay={800}>
                    <Typography variant="h1">{content?.[0]?.title}</Typography>

                    <TitleDivider />

                    <Typography variant="h4" className={classes.detailsWrapper}>
                      {content?.[0]?.subtitle && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: content?.[0]?.subtitle,
                          }}
                        ></span>
                      )}
                    </Typography>
                  </Fade>
                  <Fade triggerOnce delay={1000}>
                    <Button
                      variant="primary"
                      modifier="bright"
                      onClick={handleOpen}
                      className={classes.startDemo}
                    >
                      {content?.[0]?.button}
                    </Button>
                  </Fade>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignContent="flex-end">
              <Grid
                container
                item
                xs={12}
                justify="center"
                className={classes.arrowIndicatorRegular}
              >
                <div
                  role="button"
                  className={classes.buttonScroll}
                  onClick={scrollDown}
                >
                  <KeyboardArrowDownRoundedIcon fontSize="large" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Timeline
        otherClicks={props.click}
        clickLiveSupport={props.clickLiveSupport}
        clickEcommerce={props.clickEcommerce}
        liveSupportButtonRef={props.liveSupportButtonRef}
      />

      <Modal
        open={props?.openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default Banner;
