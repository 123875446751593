import { Box, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { useAnalytics } from "@mygrimme/gdap";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { fetchData } from "../fetch/get";
import { ContactAssistantDetails, ContactAssistantType } from "../fetch/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(2),
      zIndex: 9,
      flexDirection: "column",
      display: "flex",
    },
    details: {
      position: "absolute",
      right: 0,
      bottom: 0,
      minWidth: 0,
      height: "80px",
      width: "80px",
      background: theme.palette.background.paper,
      borderRadius: "50px",

      flexDirection: "row",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
      transition: theme.transitions.create("min-width", {
        duration: "0.2s",
        delay: "0.2s",
      }),
      [theme.breakpoints.down("sm")]: {
        height: "50px",
        width: "50px",
      },
    },
    links: {
      position: "absolute",
      right: 0,
      bottom: 80,
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0, 1, 0),
      maxHeight: 0,
      overflow: "hidden",
      transition: theme.transitions.create("max-height", {
        duration: "0.2s",
      }),
      [theme.breakpoints.down("sm")]: {
        bottom: 50,
      },
    },
    link: {
      background: theme.palette.background.paper,
      borderRadius: "27.5px",
      cursor: "pointer",
      display: "flex",
      height: "50px",
      justifyContent: "space-between",
      minWidth: "220px",
      marginBottom: "12px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
        height: "35px",
      },
    },
    linkTitle: {
      margin: theme.spacing("auto", "auto", "auto", 3),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    linkSuffix: {
      width: "50px",
      borderRadius: "50%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "35px",
      },
    },
    linkSuffixIcon: {
      width: "50%",
    },
    detailsPrefix: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "30px",
        height: "30px",
        padding: theme.spacing(0.75),
        marginLeft: theme.spacing(1),
      },
    },
    detailsPrefixIcon: {
      [theme.breakpoints.down("sm")]: {
        width: "25px",
      },
    },
    avatar: {
      bottom: 0,
      position: "absolute",
      right: 0,
      padding: theme.spacing(0),
      boxShadow: theme.shadows[3],
    },
    avatarIcon: {
      width: "80px",
      height: "80px",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
      },
    },
    detailsContent: {
      margin: theme.spacing(2, 3),
      fontWeight: 500,
      lineHeight: "1.1",
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 2),
      },
    },
    checkboxWrapper: {
      display: "flex",
      alignItems: "center",
    },
    confirmButton: {
      display: "block",
      margin: "auto",
    },
    open: {
      "& $details": {
        minWidth: 300,
        transition: theme.transitions.create("min-width", {
          duration: "0.2s",
        }),

        [theme.breakpoints.down("sm")]: {
          minWidth: 280,
          width: "auto",
        },
      },
      "& $links": {
        maxHeight: "500px",
        transition: theme.transitions.create("max-height", {
          duration: "0.2s",
          delay: "0.2s",
        }),
      },
    },
    linkItem: {
      textDecoration: "none",
      color: theme.palette.common.black,
    },
  })
);

export function ContactAssistant() {
  const classes = useStyles();
  useEffect(() => {
    const runFetch = async () => {
      setContent(await fetchData("contact-assistant"));
      setDetails(await fetchData("contact-assistant-details"));
    };
    runFetch();
  }, []);

  const [content, setContent] = useState<ContactAssistantType>();
  const [details, setDetails] = useState<ContactAssistantDetails>();
  const [show, setShow] = useState(false);

  return (
    <div
      className={classNames(classes.root, { [classes.open]: show })}
      data-testid="ca-wrapper"
    >
      <div data-testid="ca-chips" className={`${classes.links}`}>
        {details?.map((element) => {
          return (
            <a href={element.link} className={classes.linkItem} target="_blank">
              <div key={element.heading} title={element.heading}>
                <Box className={classes.link} boxShadow={3}>
                  <Typography variant="subtitle2" className={classes.linkTitle}>
                    <strong>{element.heading}</strong>
                    <br />
                    {element.value}
                  </Typography>

                  <Box className={classes.linkSuffix} boxShadow={3}>
                    <img
                      src={element?.icon?.url}
                      className={classes.linkSuffixIcon}
                      alt="whatsapp"
                    />
                  </Box>
                </Box>
              </div>
            </a>
          );
        })}
      </div>
      <Box className={classes.details} boxShadow={3}>
        <Box className={`${classes.detailsPrefix}`} boxShadow={3}>
          <img
            data-testid="ca-icon"
            src={content?.icon?.url}
            className={`${classes.detailsPrefixIcon}`}
            alt=""
          />
        </Box>
        <Typography
          data-testid="ca-text"
          variant="subtitle2"
          component="span"
          className={classes.detailsContent}
        >
          {content?.contactAssistantName && (
            <span
              dangerouslySetInnerHTML={{
                __html: content?.contactAssistantName,
              }}
            ></span>
          )}
        </Typography>
      </Box>

      {content && (
        <IconButton
          data-testid="ca-picture"
          onClick={() => {
            setShow(!show);
          }}
          className={`${classes.avatar}`}
        >
          <img
            src={content?.avatar?.url}
            className={classes.avatarIcon}
            alt=""
          />
        </IconButton>
      )}
    </div>
  );
}
