import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { TitleDivider } from "../components";
import { fetchData } from "../fetch/get";
import { PlatformsContent } from "../fetch/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timelineWrapper: {
      position: "absolute",
      right: 0,
      zIndex: 3,
      transform: "translate(0%,-50%)",
      top: "50%",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        position: "relative",
        transform: "none",
        top: "auto",
        background: theme.palette.grey[200],
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
      },
    },
    divider: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    timelineChipsRegular: {
      position: "relative",
      marginBottom: theme.spacing(3),
      background: "rgba(195,25,36,0.8)",
      cursor: "pointer",
      height: "80px",
      borderTopLeftRadius: "40px",
      borderBottomLeftRadius: "40px",
      minWidth: "310px",
      display: "flex",
      alignItems: "center",
      alignContent: "center",

      zIndex: 4,

      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
        background: "transparent",
        height: "50px",
      },
      "&:not(:last-child)::after": {
        content: '" "',
        display: "block",
        height: "24px",
        width: "10px",
        background: "rgba(195,25,36,0.8)",
        position: "absolute",
        top: "80px",
        left: "35px",
        zIndex: 1,

        [theme.breakpoints.down("sm")]: {
          top: "50px",
          left: "20px",
        },
      },
    },
    timelineChipsDataRegular: {
      wordBreak: "break-word",
      color: "white",
      lineHeight: "1.1",
      marginLeft: theme.spacing(2),
      maxWidth: "200px",
      fontSize: "18px",

      fontWeight: "lighter",
      [theme.breakpoints.down("sm")]: {
        color: "black",
        maxWidth: "150px",
        fontSize: "15px",
      },
    },
    timelineChipsIconContainer: {
      width: "80px",
      height: "80px",
      background: "white",
      borderRadius: "50%",
      boxShadow: "-1px 1px 8px 4px rgba(0,0,0,0.45)",
      display: "flex",
      justifyContent: "center",
      opacity: "1",
      alignContent: "center",

      alignItems: "center",
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
      },
    },
    timelineChipsIconRegular: {
      width: "50px",

      [theme.breakpoints.down("sm")]: {
        width: "30px",
      },
    },
    timelineTitle: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    timelineChipsContainer: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2),
        marginBottom: 0,
      },
    },
  })
);

/* eslint-disable-next-line */
interface TimelineProps {
  liveSupportButtonRef?: React.RefObject<HTMLDivElement>;
  clickLiveSupport?: () => void;
  clickEcommerce: () => void;
  otherClicks?: () => void;
}

export const Timeline = (props: TimelineProps) => {
  const classes = useStyles(props);
  const [content, setContent] = useState<PlatformsContent>();
  const [heading, setHeading] = useState<{ heading: string }>();

  useEffect(() => {
    const runFetch = async () => {
      setContent(await fetchData("sale-platforms"));
      setHeading(await fetchData("sale-platforms-mobile-heading"));
    };
    runFetch();
  }, []);

  return (
    <div className={classes.timelineWrapper}>
      <Fade triggerOnce delay={600}>
        <Typography variant="h2" className={classes.timelineTitle}>
          {heading?.heading}
        </Typography>
      </Fade>
      <TitleDivider className={classes.divider} />
      <Fade triggerOnce direction="right" delay={800}>
        <div
          data-testid="timeline-chips"
          className={classes.timelineChipsContainer}
        >
          {content?.map((element, index) => {
            return (
              <div
                key={index}
                className={classes.timelineChipsRegular}
                onClick={
                  element.name.toLocaleLowerCase() === "remote support"
                    ? props.clickLiveSupport
                    : element.name.toLocaleLowerCase() === "E-Commerce"
                    ? props.clickEcommerce
                    : props.otherClicks
                }
              >
                <div className={classes.timelineChipsIconContainer}>
                  <img
                    src={`${element?.icon?.url}`}
                    className={classes.timelineChipsIconRegular}
                    alt=""
                  />
                </div>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.timelineChipsDataRegular}
                >
                  {element.name}
                </Typography>
              </div>
            );
          })}
        </div>
      </Fade>
    </div>
  );
};

export default Timeline;
