import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Button, PageTitle } from "../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(28),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(5),
      },
    },
    outerContainer: {
      marginTop: theme.spacing(4),
    },
    informationWrapper: {
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(7),
      },
    },
    buttonWrapper: {
      paddingTop: theme.spacing(6),
      display: "flex",
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(4),
        flexDirection: "column",
      },
    },
    requestBtn: {
      marginLeft: theme.spacing(7),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(0),
      },
    },
  })
);

interface CustomerSectionProps {
  text?: string;
  imageUrl?: string;
  leftButtonText?: string;
  rightButtonText?: string;
}
export const CustomerSection = (props: CustomerSectionProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageTitle title="Der Bestseller" />
      <Container className={classes.outerContainer}>
        <Grid container xs={12}>
          <Grid item md={6} xs={12}>
            <img src={props.imageUrl} alt="" width="70%" />
          </Grid>
          <Grid item md={6} xs={12} className={classes.informationWrapper}>
            <Typography component="p" variant="body1">
              {props.text}
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button variant="primary"> {props.leftButtonText}</Button>
              <Button
                variant="default"
                modifier="dark"
                className={classes.requestBtn}
              >
                {props.rightButtonText}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
