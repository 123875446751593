import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { fetchData } from "../../fetch/get";
import FiftyFifty from "./FiftyFifty";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
    },
  })
);
export interface FiftyFiftySectionProps {
  informationSectionRef?: React.RefObject<HTMLDivElement>;
  liveSupportSectionRef?: React.RefObject<HTMLDivElement>;
  eCommerceSectionRef?: React.RefObject<HTMLDivElement>;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}
export default function FiftyFiftySection(props: FiftyFiftySectionProps) {
  const [content, setContent] = useState<any[]>();
  const classes = useStyles();

  useEffect(() => {
    const runFetch = async () => {
      setContent(await fetchData("information-sections"));
    };
    runFetch();
  }, []);
  return (
    <div ref={props.informationSectionRef}>
      {content?.map((element, index) => (
        <div
          className={classes.wrapper}
          ref={
            element?.headline === "Live Support"
              ? props.liveSupportSectionRef
              : element?.headline === "Verkaufsberater und E-Commerce"
              ? props.eCommerceSectionRef
              : undefined
          }
        >
          <FiftyFifty
            img={element?.image?.[0]?.url}
            title={element?.headline}
            icon={element?.icon?.[0]?.url}
            inverted={index % 2 === 0 ? false : true}
            key={index}
            link={element.link}
            buttonText={element?.button}
            openModal={props.openModal}
            setOpenModal={props.setOpenModal}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: element.description,
              }}
            ></span>
          </FiftyFifty>
        </div>
      ))}
    </div>
  );
}
