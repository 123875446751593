import { ButtonBase, createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const returnButtonProperites = (
  color: string,
  secondary: string,
  border: string,
  hover: string
) => ({
  color: color,
  backgroundImage: `linear-gradient(to top, ${color} 50%, ${secondary} 50%)`,
  border: `2px solid ${border}`,
  "&:hover": {
    backgroundPosition: "0 100%",
    color: hover,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.button,
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      fontWeight: "bold",
      lineHeight: 1,
      textTransform: "uppercase",
      borderRadius: theme.shape.borderRadius,
      border: `2px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
      background: "transparent",
      [theme.breakpoints.down("md")]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      backgroundSize: "100% 200%",
      transition: "all 0.3s",
    },
    bright: {},
    dark: {},
    noisy: {},
    disabled: {},
    primary: {
      "&$bright, &$noisy": {
        ...returnButtonProperites(
          theme.palette.common.white,
          theme.palette.primary.main,
          theme.palette.primary.main,
          theme.palette.primary.main
        ),
      },
      "&$dark": {
        ...returnButtonProperites(
          theme.palette.primary.main,
          theme.palette.common.white,
          theme.palette.common.white,
          theme.palette.common.white
        ),
      },
      "&$disabled": {
        ...returnButtonProperites(
          theme.palette.common.white,
          theme.palette.grey[300],
          theme.palette.grey[300],
          theme.palette.grey[300]
        ),
      },
    },
    default: {
      "&$bright": {
        ...returnButtonProperites(
          theme.palette.common.white,
          "transparent",
          theme.palette.common.white,
          theme.palette.primary.main
        ),
      },
      "&$dark": {
        ...returnButtonProperites(
          theme.palette.primary.main,
          "transparent",
          theme.palette.primary.main,
          theme.palette.common.white
        ),
      },
      "&$noisy": {
        ...returnButtonProperites(
          theme.palette.common.black,
          theme.palette.common.white,
          theme.palette.common.white,
          theme.palette.common.white
        ),
      },

      "&$bright&$disabled, &$dark&$disabled": {
        ...returnButtonProperites(
          theme.palette.grey[300],
          "transparent",
          theme.palette.grey[300],
          theme.palette.grey[300]
        ),
      },
      "&$noisy$disabled": {
        ...returnButtonProperites(
          theme.palette.grey[300],
          theme.palette.common.white,
          theme.palette.grey[300],
          theme.palette.grey[300]
        ),
      },
    },
  })
);

/* eslint-disable-next-line */
type ButtonProps = Omit<Parameters<typeof ButtonBase>[0], "variant"> &
  React.PropsWithChildren<{
    variant?: "primary" | "default";
    disabled?: boolean;
    modifier?: "bright" | "dark" | "noisy";
  }>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const classes = useStyles(props);
    const {
      children,
      classes: classesProp,
      className,
      disabled = false,
      modifier = "bright",
      variant = "default",
      ...rest
    } = props;
    const buttonBaseClasses = classesProp
      ? {
          root: classesProp.root,
          disabled: classesProp.disabled,
          focusVisible: classesProp.focusVisible,
        }
      : undefined;
    return (
      <ButtonBase
        focusRipple
        disabled={disabled}
        classes={buttonBaseClasses}
        className={classNames(
          classes.root,
          {
            [classes[variant]]: variant,
            [classes[modifier]]: modifier,
            [classes.disabled]: disabled,
          },
          className
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </ButtonBase>
    );
  }
);

export default Button;
