import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { fetchData } from "../../fetch/get";
import { NoCategories } from "../../fetch/types";
import { VegetablesInformationCard } from "../vegetables-section/VegetablesInformationCard";
import "./styles.css";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vegetablesDataContainer: { maxWidth: "1400px" },
    vegetablesDetailsWrapper: {
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(6, 3, 5, 3),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 1),
      },
    },
    vegetableItemWrapper: {
      display: "flex",
      flexGrow: 1,
      height: "250px",
      maxHeight: "400px",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("lg")]: {
        width: "12%",
      },
      [theme.breakpoints.down("md")]: {
        width: "25%",
      },

      [theme.breakpoints.down("xs")]: {
        width: "50%",
      },
    },
    descriptionWrapper: {
      padding: theme.spacing(0, 25, 2, 25),

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 3, 2, 4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2, 2),
        paddingTop: theme.spacing(4),
      },
    },
    descriptionButtonWrapper: {
      padding: theme.spacing(0, 25, 2, 25),

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 3, 2, 4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1, 2),
      },
    },
    slider: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(6, 3, 5, 3),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 1),
      },
    },
    containerWrapper: {
      justifyContent: "center",
    },
    noContentMessage: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      paddingBottom: theme.spacing(1),
    },
  })
);

export default function ProductSlider() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [content, setContent] = useState<any[]>();
  const [noContent, setNoContent] = useState<NoCategories>();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
  });

  useEffect(() => {
    const runFetch = async () => {
      setContent(await fetchData("product-categories"));

      setNoContent(await fetchData("no-catgerories-available"));
    };
    runFetch();
  }, []);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: screenWidth > 600 ? 4 : 2,
    slidesToScroll: 1,
    nextArrow: <KeyboardArrowRight />,
    prevArrow: <KeyboardArrowLeft />,
  };
  const classes = useStyles();

  return (
    <>
      {content !== undefined && content?.length > 0 ? (
        (content !== undefined && content?.length > 4 && screenWidth > 600) ||
        (content !== undefined && content?.length > 2 && screenWidth < 600) ? (
          <Slider {...settings} className={classes.slider}>
            {content?.map((element: any, index: number) => (
              <div className="vegetableItemWrapper" key={index}>
                <img src={element?.icon[0]?.url} alt="" width="100px" />
                <VegetablesInformationCard information={element.text} />
              </div>
            ))}
          </Slider>
        ) : (
          <Grid container className={classes.containerWrapper}>
            {content?.map((element: any, index: number) => (
              <Grid
                item
                xs={6}
                md={3}
                className="vegetableItemWrapper"
                key={index}
              >
                <img src={element?.icon[0]?.url} alt="" width="100px" />
                <VegetablesInformationCard information={element.text} />
              </Grid>
            ))}
          </Grid>
        )
      ) : (
        <>
          <Grid container className={classes.containerWrapper}>
            <div className={classes.noContentMessage}>
              <Typography className={classes.title} variant="h6">
                {noContent?.text}
              </Typography>
              <img src={noContent?.image?.url} width="100px" alt="" />
            </div>
          </Grid>
        </>
      )}
    </>
  );
}
