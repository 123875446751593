import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 0,
      height: theme.spacing(0.5),
      marginLeft: 0,
      marginRight: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
      },
      "&::after": {
        border: `solid ${theme.palette.primary.main} ${theme.spacing(0.25)}px`,
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(0.5),
        content: '""',
        display: "block",
        width: 70,
      },
    },
    left: {
      "&::after": {
        marginLeft: 0,
        marginRight: "auto",
      },
    },
    center: {
      "&::after": {
        margin: "auto",
      },
    },
    right: {
      "&::after": {
        marginLeft: "auto",
        marginRight: 0,
      },
    },
  })
);
interface TitleDividerProps {
  className?: string;
  variant?: "left" | "center" | "right";
}

export const TitleDivider = (props: TitleDividerProps) => {
  const { className, variant = "left" } = props;
  const classes = useStyles(props);
  return (
    <hr
      data-testid="divider"
      className={classNames(classes.root, classes[variant], className)}
    />
  );
};

export default TitleDivider;
