import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { fetchData } from "../fetch/get";
import { BrandsType } from "../fetch/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      color: theme.palette.common.black,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    heading: {
      display: "flex",
      justifyContent: "center",
      height: "100%",
      paddingTop: theme.spacing(2),
      flex: "0.4",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    logo: {
      //   maxWidth: "25%",
      paddingTop: theme.spacing(2),
    },
  })
);

/* eslint-disable-next-line */
interface BrandsProps {}

export const Brands = (props: BrandsProps) => {
  const classes = useStyles();
  const [brands, setBrands] = useState<BrandsType>([]);
  const [heading, setHeading] = useState<{ Heading: string }>();

  useEffect(() => {
    const runFetch = async () => {
      setBrands(await fetchData("brands"));
      setHeading(await fetchData("brand-section-heading"));
    };
    runFetch();
  }, []);
  return (
    <div className={classes.root}>
      {brands.length > 0 ? (
        <div className={classes.heading}>
          <Fade triggerOnce delay={200} direction="left">
            <Typography className={classes.title} variant="h6">
              {heading?.Heading}
            </Typography>
          </Fade>
        </div>
      ) : null}
      <div className={classes.content}>
        {brands.length > 0
          ? brands?.map((element, index) => (
              <div className={classes.logo}>
                <Fade triggerOnce delay={200} direction="left">
                  <img src={element?.logo?.url} alt="" width="200px" />
                </Fade>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default Brands;
