import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { PageTitle } from "../../components";
import { applicationsImages } from "../../data/applicationsSectionData";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(6),
    },
    imageWrapper: {
      paddingTop: theme.spacing(5.2),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0),
        justifyContent: "center",
        paddingTop: theme.spacing(2),
      },
    },
    applicationImage: {
      padding: theme.spacing(0, 2),
      position: "relative",
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(2),
      },
    },
    subIntroduction: {
      paddingTop: theme.spacing(2),
    },

    applicationImageWithDetails: {
      position: "relative",
    },
    imageOverlay: {
      width: "100",
      height: "98%",
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      margin: "16px",
      marginTop: "0px",
      opacity: "0",

      textAlign: "start",
      alignItems: "start",
      fontSize: "24px",
      color: "white",
      display: "flex",
      flexDirection: "column",
      zIndex: 100,
      paddingLeft: theme.spacing(2),
      transition: "opacity 0.3s ease-in",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),

      "&:hover": {
        opacity: "0.8",
      },
    },
    imageOverlayContent: {
      paddingBottom: theme.spacing(2),
    },
    title: {
      paddingBottom: theme.spacing(1),
    },
  })
);

interface ApplicationsSectionProps {}
export function ApplicationsSection(props: ApplicationsSectionProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PageTitle title={"Unser Starkes Team"} />
      <Grid container justify="center" className={classes.imageWrapper}>
        {applicationsImages.map((element) => (
          <Grid
            item
            key={element.index}
            className={classes.applicationImageWithDetails}
          >
            <img
              src={element.image}
              className={classes.applicationImage}
              alt=""
              width="300px"
            />
            {element.description && (
              <>
                <Typography className={classes.imageOverlay}>
                  <div className={classes.imageOverlayContent}>
                    <Typography className={classes.title} variant="h5">
                      Niklas Jahn
                      {/* {element.description} */}
                    </Typography>
                    <Typography component="p">
                      10 Jahre Erfahrung in Speziallösungen
                    </Typography>
                  </div>
                </Typography>
              </>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
