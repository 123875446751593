import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import {
  Banner,
  CallToAction,
  ContactAssistant,
  Footer,
  VegetablesSection,
} from "./components";
import Brands from "./components/Brands";
import InformationSection from "./components/fifty-fifty-container/InformationSection";
import theme from "./theme/src";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      maxWidth: "100%",
      overflow: "hidden",
    },
  })
);

function App() {
  const informationSectionRef = useRef(null);
  const bannerRef = useRef(null);
  const liveSupportSectionRef = useRef(null);
  const liveSupportButtonRef = useRef(null);
  const eCommerceSectionRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();

  const scrollToInformation = (ref: React.RefObject<HTMLDivElement>) =>
    ref.current !== null &&
    window.scrollTo({
      top: ref?.current?.offsetTop - 64,
      left: 0,
      behavior: "smooth",
    });
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <ContactAssistant />

        <Banner
          liveSupportButtonRef={liveSupportButtonRef}
          bannerRef={bannerRef}
          click={() => scrollToInformation(informationSectionRef)}
          clickLiveSupport={() => scrollToInformation(liveSupportSectionRef)}
          clickEcommerce={() => scrollToInformation(eCommerceSectionRef)}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
        <Brands />
        <VegetablesSection />
        <InformationSection
          liveSupportSectionRef={liveSupportSectionRef}
          informationSectionRef={informationSectionRef}
          eCommerceSectionRef={eCommerceSectionRef}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
        <CallToAction
          title="Starke Typen, starke Lösungen!"
          buttonText="ANfragen"
          img="/img/calltoaction.png"
        >
          Wir wissen seit längerem worauf es ankommt. Egal was Sie brauchen wir
          finden die perfekte Abstreiferlösung für Sie.
        </CallToAction>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
