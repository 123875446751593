import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { TitleDivider } from "../components";
import { Heading } from "../fetch/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(3),
      },
    },
    headingWrapper: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

export interface PageTitleProps {
  title: Heading | string;
}
export function PageTitle(props: PageTitleProps) {
  const classes = useStyles();
  return (
    <Container>
      <Grid
        container
        justify="space-between"
        direction="row"
        className={classes.root}
      >
        <div className={classes.headingWrapper}>
          <Typography variant="h2">{props.title}</Typography>
          <TitleDivider />
        </div>
      </Grid>
    </Container>
  );
}
