import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { PageTitle } from "../../components";
import { fetchData } from "../../fetch/get";
import { Heading } from "../../fetch/types";
import Slider from "../slider/Slider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vegetablesDataContainer: {
      maxWidth: "1400px",
    },
    vegetablesDetailsWrapper: {
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(6, 3, 5, 3),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 1),
      },
    },
    vegetableItemWrapper: {
      display: "flex",
      flexGrow: 1,
      height: "250px",
      maxHeight: "400px",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("lg")]: {
        width: "12%",
      },
      [theme.breakpoints.down("md")]: {
        width: "25%",
      },

      [theme.breakpoints.down("xs")]: {
        width: "50%",
      },
    },
    descriptionWrapper: {
      padding: theme.spacing(0, 25, 2, 25),

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 3, 2, 4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2, 2),
        paddingTop: theme.spacing(4),
      },
    },
    descriptionButtonWrapper: {
      padding: theme.spacing(0, 25, 2, 25),

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 3, 2, 4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1, 2),
      },
    },
  })
);

interface VegetablesSectionProps {}
export const VegetablesSection = (props: VegetablesSectionProps) => {
  const [content, setContent] = useState<Heading>();

  useEffect(() => {
    const runFetch = async () => {
      setContent(await fetchData("product-categories-header"));
    };
    runFetch();
  }, []);
  const classes = useStyles();

  return (
    <>
      <Fade triggerOnce>
        <PageTitle
          title={content?.Heading !== undefined ? content?.Heading : ""}
        />
      </Fade>
      <Fade triggerOnce>
        <Container className={classes.vegetablesDataContainer}>
          <Slider />
        </Container>
      </Fade>
    </>
  );
};
