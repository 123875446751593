import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      margin: theme.spacing(0, 1),

      boxShadow: "0px 6px 8px  #00000033",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(3, 3),
      borderTop: `8px solid ${theme.palette.primary.main}`,
      maxHeight: "400px",
      width: "70%",
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    },
    information: {
      maxWidth: "100px",
      display: "inline-block",
      textAlign: "center",
      overflowWrap: "break-word",
    },
  })
);
interface VegetablesInformationCardProps {
  information: string;
}
export function VegetablesInformationCard(
  props: VegetablesInformationCardProps
) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.information}>
        {props.information}
      </Typography>
    </div>
  );
}
