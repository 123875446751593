import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TitleDivider } from "../../components";
import { fetchData } from "../../fetch/get";
import { FooterContent, FooterLinks } from "../../fetch/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      marginTop: theme.spacing(10),
    },
    contacts: {
      backgroundColor: theme.palette.grey[900],
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      "& a": {
        marginRight: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
          marginRight: theme.spacing(2),
        },
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    nav: {
      backgroundColor: theme.palette.grey[600],
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      textAlign: "center",
      "& a": {
        color: theme.palette.common.white,
        textDecoration: "none",
      },
      "& > a": {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    footerTextLink: {
      color: theme.palette.common.white,
      textDecoration: "none",
      display: "block",
      transition: "0.3s ease-in-out",
      "&:hover": {
        transition: "0.3s ease-in-out",
        color: theme.palette.primary.main,
      },
    },
    socialContainer: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(4),
      },
    },
    socialIcon: {
      color: theme.palette.common.white,
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        paddingRight: theme.spacing(2),
      },
    },
    copyright: {
      textAlign: "right",
      fontFamily: "Calibri",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },
    footerTextExtraPadding: {
      paddingBottom: theme.spacing(1),
    },
    footerGreyLinksContainer: {
      textAlign: "left",
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    footerGreyLink: {
      marginRight: theme.spacing(5),
      transition: "0.3s ease-in-out",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      "&:hover": {
        transition: "0.3s ease-in-out",
        color: theme.palette.primary.main,
      },
    },
  })
);

/* eslint-disable-next-line */
export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const classes = useStyles(props);
  const [title, setTitle] = useState<{ heading: string }>();
  const [footerContent, setFooterContent] = useState<FooterContent>();
  const [footerLinks, setFooterLinks] = useState<FooterLinks>();
  const [copyRight, setCopyRight] = useState<{ text: string }>();
  useEffect(() => {
    const runFetch = async () => {
      setTitle(await fetchData("footer-header"));
      setFooterContent(await fetchData("footer-content"));
      setFooterLinks(await fetchData("footer-links"));
      setCopyRight(await fetchData("copyright"));
    };
    runFetch();
  }, []);
  return (
    <footer className={classes.root}>
      <Container
        className={classes.contacts}
        maxWidth={false}
        disableGutters
        data-testid="footer-grid-container"
      >
        <Container>
          <Typography variant="h3">{title?.heading}</Typography>

          <TitleDivider />

          <Typography
            variant="body2"
            className={classes.footerTextExtraPadding}
          >
            <strong>{footerContent?.companyName}</strong>
          </Typography>
          <Typography
            variant="body2"
            className={classes.footerTextExtraPadding}
          >
            {footerContent?.street}
            <br />
            {footerContent?.postcode}
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="tel: +49 5491 9692-0"
            className={classes.footerTextLink}
          >
            {footerContent?.telephone}
          </Typography>

          <Typography
            variant="body2"
            component="a"
            href={`mailto: ${footerContent?.email}`}
            className={classes.footerTextLink}
          >
            {footerContent?.email}
          </Typography>

          <Typography
            variant="body2"
            component="a"
            href={footerContent?.website}
            className={classes.footerTextLink}
            target="_blank"
          >
            {footerContent?.website}
          </Typography>
        </Container>
      </Container>
      <Container
        className={classes.nav}
        maxWidth={false}
        disableGutters
        data-testid="footer-nav-container"
      >
        <Container>
          <Grid container>
            <Grid
              item
              component="nav"
              xs={12}
              md={8}
              className={classes.footerGreyLinksContainer}
            >
              {footerLinks?.map((element, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  className={classes.footerGreyLink}
                  href={element?.link}
                  component="a"
                  target="_blank"
                >
                  {element?.linkName}
                </Typography>
              ))}
            </Grid>
            <Grid
              item
              component="p"
              xs={12}
              md={4}
              className={classes.copyright}
            >
              {copyRight?.text}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </footer>
  );
};
