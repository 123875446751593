import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginTop: theme.spacing(5) },

    leftBlockDetailsWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    rightBlockDetailsWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    blockImage: {
      position: "relative",
    },
    leftBlockTitle: {
      paddingTop: theme.spacing(6),

      textAlign: "end",
      [theme.breakpoints.down("sm")]: {
        textAlign: "start",
      },
    },
    rightBlockTitle: {
      paddingTop: theme.spacing(6),

      textAlign: "start",
      [theme.breakpoints.down("sm")]: {
        textAlign: "start",
      },
    },
    leftBlockInformation: {
      paddingTop: theme.spacing(2),
      textAlign: "end",
      paddingLeft: theme.spacing(15),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(0),
        textAlign: "start",
      },
    },
    rightBlockInformation: {
      paddingTop: theme.spacing(2),
      textAlign: "start",
      paddingRight: theme.spacing(15),
      [theme.breakpoints.down("sm")]: {
        textAlign: "start",
        paddingRight: theme.spacing(0),
      },
    },
    leftChipsIconContainer: {
      width: "80px",
      height: "80px",
      background: "white",
      borderRadius: "50%",
      boxShadow: `0px 3px 8px #0000004D`,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      position: "absolute",
      right: "12px",
      bottom: "-9%",

      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
      },
    },
    rightChipsIconContainer: {
      width: "80px",
      height: "80px",
      background: "white",
      borderRadius: "50%",
      boxShadow: `0px 3px 8px #0000004D`,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      position: "absolute",
      left: "12px",
      bottom: "-9%",

      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
        right: "12px",
        background: "white",
        left: "auto",
      },
    },
    chipsIconRegular: {
      width: "auto",
      height: "35px",
      [theme.breakpoints.down("sm")]: {
        height: "25px",
      },
    },
  })
);
export interface FiftyfiftyBlockProps {
  align: string;
  title: string;
  text: string;
  imageUrl: string;
  iconUrl: string;
}
export function FiftyfiftyBlock(props: FiftyfiftyBlockProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.blockImage}>
        <img src={props.imageUrl} alt="" width="100%" />
        <div
          className={
            props.align === "left"
              ? classes.leftChipsIconContainer
              : classes.rightChipsIconContainer
          }
        >
          <img
            src={props.iconUrl}
            className={classes.chipsIconRegular}
            alt=""
          />
        </div>
      </div>
      <div
        className={
          props.align === "left"
            ? classes.leftBlockDetailsWrapper
            : classes.rightBlockDetailsWrapper
        }
      >
        <Typography
          className={
            props.align === "left"
              ? classes.leftBlockTitle
              : classes.rightBlockTitle
          }
          variant="h2"
        >
          {props.title}
        </Typography>
        <Typography
          component="p"
          variant="body1"
          className={
            props.align === "left"
              ? classes.leftBlockInformation
              : classes.rightBlockInformation
          }
        >
          {props.text}
        </Typography>
      </div>
    </div>
  );
}
